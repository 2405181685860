<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'总工单详情':'总工单详情'"
    width="1200px"
    :modal="false"
    top="3vh"
    @close="$reset('form')">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="maintenance/maintain-order/page">
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="orderStatusDesc" label="工单状态">
      </el-table-column>
      <el-table-column prop="typeDesc" label="保养类型"></el-table-column>
      <el-table-column prop="planDate" label="计划保养日期">
        <template slot-scope="scope">
          <span></span>
          {{scope.row.planDate?$moment(scope.row.planDate).format("YYYY-MM-DD"):""}}
        </template>
      </el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码"></el-table-column>
      <el-table-column prop="createTime" label="品牌/型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="认领时间">
        <template slot-scope="scope">
          <span v-if="scope.row.assignedTimeout">
            {{scope.row.assignedTimeoutTime}}({{scope.row.assignedTimeoutLevel}}级超时)
          </span>
          <span v-else>{{scope.row.assignedTime}}(未超时)</span>
        </template>
      </el-table-column>>
      <el-table-column prop="arrivedTime" label="到达时间"></el-table-column>
      <el-table-column prop="createTime" label="完成时间">
        <template slot-scope="scope">
          <span v-if="scope.row.completedTimeout">
            {{scope.row.completedTimeoutTime}}({{scope.row.completedTimeoutLevel}}级超时)
          </span>
          <span v-else>{{scope.row.completedTime}}(未超时)</span>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('全部详情')" type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
    </span>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </el-dialog>
</template>
<script>
  import EditPage from "@/views/maintenance/maintenance/maintenanceOrder/MaintenanceDetailNew";

  export default {
    components: {EditPage},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        id:"",
        value1:false,
        yaixs:[],
        formatData:{
          xaxis: [],
          data:[],
          record:[],
        },
        filter: {
          maintenanceType:"as_need",
          orderStatus: "",
          orderColumnName:"createTime",
          asc:0,
        },
        model:{},
      };
    },
    methods: {
      open(teamId) {
        this.filter.teamId= teamId;
        // this.model.teamId = teamId;
        this.dialogVisible = true;
        this.getList(1);
      },
      getList(pageNum) {
        let time=window.setTimeout(()=>{
          this.$refs.vmTable.getList(pageNum);
          window.clearTimeout(time);
        },200);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.isp) {
              this.model.isp=null;
            }
            this.$http
              .save("elevator-iot/device", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      realEstateSelect(row) {
        this.$set(this.model,"realEstateId", row.id);
        this.$set(this.model,"realEstateName", row.name);

      },
      buildingSelect(row) {
        this.$set(this.model,"buildingId", row.id);
        this.$set(this.model,"buildingName", row.name);

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
